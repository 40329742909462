// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseUrl: 'https://folio.qa.aodocs.app/',
  apiUrl: 'api/',
  clientId: '880238724920-8ffjods6bbf8lrc6cc864qf5embi4d9g.apps.googleusercontent.com',
  aodocsUrl: 'https://aodocs-staging.altirnao.com/',
  version: '4.2.0',
  production: false,
  firebase: {
    apiKey: 'AIzaSyBWo5_zgbMgKf5nbYrn749NgvNGkBri23k',
    authDomain: 'ao-docs-staging.firebaseapp.com',
    databaseURL: 'https://ao-docs-staging.firebaseio.com',
    projectId: 'ao-docs-staging',
    storageBucket: 'ao-docs-staging.appspot.com',
    messagingSenderId: '563231141203',
    appId: '1:563231141203:web:69fd2c6b041e95f93845a6',
    measurementId: 'G-0RJZXTDXBR',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
